.scanner-container {
    text-align: center;
    padding: 20px;
  }
  
  .video-container {
    border: 2px solid #007bff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    max-width: 100%; /* Ensure it doesn't exceed the width of the screen */
    aspect-ratio: 16 / 12; /* Example aspect ratio - adjust as needed */
    overflow: hidden; /* To ensure the video fits within the container */
  }
  
  .video-container video {
    width: 100%;
    height: auto;
    max-height: 100%; /* Add a max height to the video */
  }
  .result-container {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .result-text {
    color: #28a745;
    font-weight: bold;
  }
  
  .scan-history {
    list-style: none;
    padding: 0;
  }
  
  .scan-history li {
    background-color: #f8f9fa;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
  }
  

  .scanner-container .video-container {
    position: relative;
  }

  .result-panel, .history-panel {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .result-title, .history-title {
    font-weight: bold;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .result-content {
    color: #28a745;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .scan-history {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .history-item {
    background-color: #ffffff;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .item-data {
    font-weight: bold;
  }
  
  .item-timestamp {
    font-size: 0.85em;
    color: #6c757d;
  }
/* Custom styles for Calendar Heatmap */
.react-calendar-heatmap text {
    font-size: 10px; /* Adjust this value as needed */
  }
  .react-tooltip {
    z-index: 1500; /* Set a high z-index value */
  }
  .color-empty {
    fill: #ebedf0;
  }
  .color-level-1 {
    fill: #d6e685;
  }
  .color-level-2 {
    fill: #8cc665;
  }
  .color-level-3 {
    fill: #44a340;
  }
  .color-level-4 {
    fill: #1e6823;
  }
  .color-level-5 {
    fill: #004400;
  }